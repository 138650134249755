// import React, { useState, memo, useEffect } from 'react'
import React, { useRef, memo, useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom'

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import 'swiper/css/pagination';


import { apiBaseUrl } from '../../config/config'
import axios from 'axios'
const CategoryMemorize = () => {
    const [poster, setPoster] = useState([
        // { poster: poster1, posterName: 'C. H. Loom',cId:5},
        // { poster: poster2, posterName: 'T. Georgette',cId:11},
        // { poster: poster3, posterName: 'Lambani',cId:8},
        // { poster: poster4, posterName: 'Kantha',cId:1},
        // { poster: poster5, posterName: 'Hand-Jamdani',cId:3 },
        // { poster: poster6, posterName: 'Pen K. Kari',cId:12},
        // { poster: poster7, posterName: 'South Silk',cId:10},
        // { poster: poster8, posterName: 'Tussar',cId:9},
    ])

    const getCategoryList = () => {
        axios.get(`${apiBaseUrl}api/category-list`)
            .then((res) => {
               
                setPoster(res.data)
            })
            .catch((err) => console.log( err))
    }
    useEffect(() => {
        getCategoryList()
    }, [])
    const navigate = useNavigate()
    const goToCategory = (id) => {
        // localStorage.setItem("CId", id)
        navigate(`/productlist/${id}`);
    }
    const size = poster.length


    
    const slideRefCat = useRef();
    // const swiperSlide = useSwiperSlide();

    const handlenext = () => {
        slideRefCat.current.swiper.slideNext();
    };
    const handleprev = () => {
        slideRefCat.current.swiper.slidePrev();
    };

    return (
        <div className='w-full  mt-10 relative '>
            <h1 className="text-4xl flex justify-center my-10 font-thin">Product Categories</h1>
            <div className='relative space-x-20 px-10 lg:px-0 lg:mx-60'>
                <Swiper
                    loop={true}
                    slidesPerView={7}
                    modules={[Autoplay, Pagination, Navigation]}
                    // spaceBetween={60}
                    spaceBetween={10}
                    // centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    // navigation
                    // pagination={{ clickable: true, }}
                    // modules={[Autoplay, Pagination, Navigation]}
                    className=""
                    ref={slideRefCat}
                    breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        375: {
                          slidesPerView: 2,
                          spaceBetween: 10,
                        },
                        548: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        640: {
                          slidesPerView: 3,
                          spaceBetween: 10,
                        },
                        768: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        900: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        1024: {
                          slidesPerView: 4,
                          spaceBetween: 10,
                        },
                        1280: {
                          slidesPerView: 5,
                          spaceBetween: 10,
                        },
                        1600: {
                          slidesPerView: 6,
                          spaceBetween: 10,
                        },
                        2100: {
                          slidesPerView: 7,
                          spaceBetween: 10,
                        },
                    }}
                >
                
                    {
                        poster.slice(0, 9).map((item, index) => {
                            return (
                                <SwiperSlide className="" key={index}>
                                    <div className='pb-5' onClick={() => goToCategory(item?.id)}>
                                        <div className="flex justify-center item-center">
                                            <div className='bg-gradient-to-tr from-yellow-400 to-fuchsia-600 p-1 rounded-full cursor-pointer'>
                                                <div className='block  bg-white p-1 rounded-full hover:-rotate-6 transition'>
                                                    <img className='w-[110px] h-[110px] rounded-full object-cover object-top shadow-xl ' src={item?.img_path} alt='poster' />
                                                </div>
                                            </div>
                                        </div>
                                        <p className='font-semibold tracking-tighter mt-5 text-center text-sm'>{item?.category_name}</p>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

                <div className="sliderNav-wrapper">
                    <svg onClick={() => slideRefCat.current.swiper.slidePrev()} className='slideNav-previous w-10 h-10 rounded-full opacity-50 hover:opacity-100 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute right-5 top-[50%] -translate-y-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                    <svg onClick={() => slideRefCat.current.swiper.slideNext()} className='slideNav-next w-10 h-10 rounded-full opacity-50 hover:opacity-100 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute left-5 top-[50%] -translate-y-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </div>
            </div>
        </div>
    )
}
const Category = memo(CategoryMemorize)
export default Category
