import React,{memo} from 'react'

import './homeCommon.css'
import Layout from '../../components/layout/Layout'
import BannerSection from '../bannerSection/BannerSection'
import AllProducts from '../allproducts/AllProducts'
import DiscoverMoreBestsellers from '../discoverMoreBestsellers/DiscoverMoreBestsellers'
import OfferPage from '../offerPage/OfferPage'
import Testimonial from '../../components/testimonial/Testimonial'
import Category from '../category/Category'
import NewArrivals from '../newArrivals/NewArrivals'
import ExclusiveCollections from '../exclusiveCollections/ExclusiveCollections'
import {Helmet} from "react-helmet";
 
const HomeMemorize = () => {
  return (
    <Layout>
      <BannerSection />
      <Category/>
      {/* title */}
      {/* <NewArrivals/> */}
      <AllProducts />
      <ExclusiveCollections/>
      <OfferPage/>
      <DiscoverMoreBestsellers/>
      <Testimonial/>
    </Layout>
  )
}
const Home = memo(HomeMemorize)
export default Home