import {setProductList} from '../reducers/ProductList';
import { axiosInstances } from './api';
import { getFromLocalStorage } from '../helpers/helpers';

export const listCategory = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.categoryList.get(`/api/category-list`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const listProducts = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.productList.get(`/api/product-list`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const trackOrder = (orderId) => async dispatch => {
  var request={
    order_id: orderId
  }
  return new Promise((resolve, reject) => {
    axiosInstances.productList.post(`/api/ordertracking`, request)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const subscribeEmail = (email) => async dispatch => {
  var request={
    email: email
  }
  return new Promise((resolve, reject) => {
    axiosInstances.productList.post(`/api/subscribe`, request)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const productDetails = (data) => async dispatch => {
  let apiData = {
    id: data,
  }
  return new Promise((resolve, reject) => {
    axiosInstances.productList.get(`/api/product-details/${data}`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const cmsAboutUs = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.aboutUs.get(`/api/aboutus`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const cmsEvents = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.events.get(`/api/events`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const cmsCompanyDetails = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.companyDetails.get(`/api/Companydetails`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const cmsTopbanner = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.topBanner.get(`/api/topbanner`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};

export const cmsBottombanner = () => async dispatch => {
  return new Promise((resolve, reject) => {
    axiosInstances.bottomBanner.get(`/api/bottombanner`)
      .then((response) => {
        resolve(response.data);
      }).catch((err) => {
        let error = { ...err };
        reject(error.response.data);
      });
  });
};