import React, { memo, useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import { apiBaseUrl } from '../../config/config'
import { getFromLocalStorage, setToLocalStorage, removeAllFromLocalStorage } from "../../helpers/helpers";
import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom'

import { LuPackagePlus } from "react-icons/lu"

const ShippingMemorize = ({className}) => {
    const [token, setToken] = useState(getFromLocalStorage("access_token"))
    const [showAddress, setShowAddress] = useState(true)
    const [addressList, setaddressList] = useState()
    const [phone, setPhone] = useState()
    const [defaultShipping, setDefaultShipping] = useState()
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate()
    const [addressFormError, setAddressformError] = useState()
    const [addressForm, setAddressform] = useState({
        name: "",email: "",address: "",state: '',city: "",
        landmark: "",zip: '',phone: '',default_shipping: 1
    })
    
    useEffect(() => {
        console.log("addressformerror", addressFormError)       
    }, [addressFormError])

    const [deleteMessage, setDeleteMessage]=useState()

    let states = [
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chhattisgarh",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal",
        "Andaman and Nicobar Islands",
        "Chandigarh",
        "Dadra and Nagar Haveli",
        "Daman and Diu",
        "Delhi",
        "Lakshadweep",
        "Puducherry"
    ]

    const stateNameList = () => {
        return (
            states.map((item, index) => {
                return (
                    <option className=''  >{item}</option>
                )
            })
        )
    }

    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };

    const payload = {
        name: addressForm.name,
        address: addressForm.address,
        landmark: addressForm.landmark,
        state: addressForm.state,
        city: addressForm.city,
        zip: addressForm.zip,
        phone: phone?"+91"+phone:'',
        default_shipping: addressForm.default_shipping ? 1 : 0
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(addressForm.state !== ""){
            axios.post(`${apiBaseUrl}api/add_address`, payload, { headers })
            .then((res) => {
                NotificationManager.success(res.data.message)
                setAddressform({
                    name: "",
                    email: "",
                    address: "",
                    state: '',
                    city: "",
                    landmark: "",
                    zip: '',

                })
                setShowAddress(true)
            })
            .catch((err) => {
                var errors= {}
                console.log("This is error", err, err.response.data.message)
                errors.address = err.response.data.message?.address?.join(", ") 
                errors.state = err.response.data.message?.state?.join(", ")
                errors.city = err.response.data.message?.city?.join(", ")
                errors.landmark = err.response.data.message?.landmark?.join(", ")
                errors.zip = err.response.data.message?.zip?.join(", ")
                errors.phone = err.response.data.message?.phone?.join(", ")
                errors.name = err.response.data.message?.name?.join(", ")
                errors.email = err.response.data.message?.email?.join(", ")
                setAddressformError(errors)
            })
        }else{
            NotificationManager.error('All fields are required');
        }
      
    }
    const logOut = () => {
        removeAllFromLocalStorage()
        setToLocalStorage("islogin", false)
        navigate("/login")
    }
    const getAddresList = () => {
        axios.get(`${apiBaseUrl}api/list_address`, { headers })
            .then((res) => {
                console.log("This is addres list", res.data.message)
                setaddressList(res.data.message)
                setDefaultShipping(res.data.message.default_shipping)
            })
            .catch((err) => {
                var hasTokenExpired= err.response.data.message==="Unauthorized access"
                if(hasTokenExpired){
                    logOut();
                }
                console.log("This is error at the time of cart fetching 2", hasTokenExpired)    
                console.log("This is error at the time of address fetching", err)
            })
    }

    useEffect(() => {
        getAddresList()
    },[showAddress])

    const edithandler = (id, name, phone, address, state, city, landmark, zip, default_shipping) => {
        localStorage.setItem("addressId", id)
        localStorage.setItem("aname", name)
        localStorage.setItem("aphone", phone.toString()?.substr(2))
        localStorage.setItem("aaddress", address)
        localStorage.setItem("astate", state)
        localStorage.setItem("acity", city)
        localStorage.setItem("alandmark", landmark)
        localStorage.setItem("azip", zip)
        // localStorage.setItem("default_shipping", default_shipping)
        navigate("/profile/editaddress")
    }

    const deleteAddresshandler = (id) =>{
        axios.delete(`${apiBaseUrl}api/deleteaddress/${id}`,{headers})
        .then((res)=>{
            NotificationManager.success(res.data.message)
            getAddresList()

        })
        .catch((err)=>console.log("Error at the time of address delete",err))
    }

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);

        if (addressList) {
            setaddressList(
                addressList.filter((item) =>
                    item.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        }
    };


    return (
        <div  className={`${className}`}>
            {
                showAddress ?
                    <div className="flex flex-col ">
                        <div className='w-full flex justify-end'>
                            <button onClick={() => setShowAddress(!showAddress)} className='bg-red-600 text-white text-sm flex items-center px-2.5 py-1.5 shadow-lg rounded mb-2'><LuPackagePlus className='mr-1'/> Add address</button>
                        </div>
                        <div className="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
                            <div className="py-2 inline-block min-w-full ">

                                <div className="overflow-hidden">
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:grid-cols-2">

                                    {
                                        addressList?.map((item, index) => {
                                            console.log('SD Default Shipping', item)
                                            return (
                                                <div key={index} className="border p-4 rounded-lg shadow-md bg-white relative group">
                                        
                                                    <div className="absolute top-2 right-2 flex space-x-2">

                                                        <button className="focus:outline-none" onClick={() => edithandler(item.id, item.name, item.phone, item.address, item.state, item.city, item.landmark, item.zip, item.default_shipping)}>
                                                        <svg className="feather feather-edit" fill="none" height="20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/></svg>
                                                        </button>
                                                
                                                        <button className="focus:outline-none" onClick={() => deleteAddresshandler(item.id)}>
                                                        <svg className="feather feather-trash-2" fill="none" height="20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><polyline points="3 6 5 6 21 6"/><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"/><line x1="10" x2="10" y1="11" y2="17"/><line x1="14" x2="14" y1="11" y2="17"/></svg>
                                                        </button>
                                                    </div>

                                        
                                                    <div className="mb-4">
                                                        <h2 className="font-semibold text-lg">{item.name}</h2>
                                                        <p className="text-sm">Phone : {item.phone}</p>
                                                        <p className="text-sm" >{item.address},{item.state},{item.city} - {item.zip}</p>
                                                        
                                                        <p className="text-sm text-#85adad-500">Landmark : {item.landmark}</p>
                                                    </div>

                                                    {item.id == item.default_shipping && (
                                                        <div className="absolute bottom-2 right-2 px-2 py-1 bg-green-200 text-sm rounded-lg">
                                                            Default
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        })
                                    }

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    :
                    <>
                        <div className="w-full bg-white shadow-xl p-10 ">
                            <div className='w-full flex justify-end'>
                                <button onClick={() => setShowAddress(!showAddress)} className='bg-red-600 text-white text-sm h-8 w-28 shadow-lg rounded m-3 p-1'>Address List</button>
                            </div>
                            <div className="border-b border-gray-900/10 pb-12">
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive order.</p>

                                <div className="mt-10 grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-8">
                                    <div className="sm:col-span-3 col-start-1">
                                        <label for="first-name" className="block text-sm font-medium leading-6 text-gray-900">Full name</label>
                                        <div className="mt-2">
                                            <input type="text" name="first-name" value={addressForm.name} onChange={(e) => setAddressform({ ...addressForm, name: e.target.value })} id="first-name" autocomplete="given-name" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.name}</small>
                                    </div>

                                    <div className="sm:col-span-3 col-start-1">
                                        <label for="email" className="block text-sm font-medium leading-6 text-gray-900">Email address</label>
                                        <div className="mt-2">
                                            <input id="email" name="email" value={addressForm.email} onChange={(e) => setAddressform({ ...addressForm, email: e.target.value })} type="email" autocomplete="email" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.email}</small>
                                    </div>


                                    <div className="sm:col-span-3 col-start-1">
                                        <label for="country" className="block text-sm font-medium leading-6 text-gray-900">State</label>
                                        <div className="mt-2">
                                            <select id="State" name="State" onChange={(e) => setAddressform({ ...addressForm, state: e.target.value })} autocomplete="State-name" className="block w-full rounded-md border-0 py-1.5 
                                            focus:ring-indigo-600 sm:text-sm sm:leading-6">

                                                {stateNameList()}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3 col-start-1">
                                        <label for="phone" className="block text-sm font-medium leading-6 text-gray-900">Phone</label>
                                        <div className="mt-2">
                                            <input type="text" value={phone} name="phone" onChange={(e) =>setPhone(e.target.value.slice(0,10))} id="phone" autocomplete="phone" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.phone}</small>
                                    </div>

                                    <div className="col-span-full">
                                        <label for="street-address" className="block text-sm font-medium leading-6 text-gray-900">Street address</label>
                                        <div className="mt-2">
                                            <textarea type="text" name="street-address" onChange={(e) => setAddressform({ ...addressForm, address: e.target.value })} id="street-address" autocomplete="street-address" className="block w-full min-h-[150px] rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" >
                                            </textarea>
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.address}</small>
                                    </div>

                                    <div className="sm:col-span-2 sm:col-start-1">
                                        <label for="city" className="block text-sm font-medium leading-6 text-gray-900">City</label>
                                        <div className="mt-2">
                                            <input type="text" name="city" onChange={(e) => setAddressform({ ...addressForm, city: e.target.value })} id="city" autocomplete="address-level2" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.city}</small>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label for="region" className="block text-sm font-medium leading-6 text-gray-900">Land Mark</label>
                                        <div className="mt-2">
                                            <input type="text" name="landmark" onChange={(e) => setAddressform({ ...addressForm, landmark: e.target.value })} id="region" autocomplete="address-level1" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.landmark}</small>
                                    </div>

                                    <div className="sm:col-span-2">
                                        <label for="postal-code" className="block text-sm font-medium leading-6 text-gray-900">ZIP / Postal code</label>
                                        <div className="mt-2">
                                            <input type="text" name="postal-code" onChange={(e) => setAddressform({ ...addressForm, zip: e.target.value })} id="postal-code" autocomplete="postal-code" className="block w-full rounded-md border-0 py-1.5 px-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                                        </div>
                                        <small className='text-red-600'>{addressFormError?.zip}</small>
                                    </div>
                                </div>
                            </div>
                            <form onSubmit={submitHandler}>
                                <div className='flex mt-5'>
                                    <input type='checkbox' id='dflt-ship-add' defaultChecked value={addressForm.default_shipping} onChange={(e) => setAddressform({ ...addressForm, default_shipping: e.target.checked })} className='mr-5' />
                                    <label htmlFor='dflt-ship-add'> Set Default shipping address</label>
                                </div>

                                <div className="mt-6 flex items-center justify-end gap-x-6">
                                    <button type="button" className="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
                                    <button type="submit" className="rounded-md bg-red-600 w-28 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Save</button>
                                </div>
                            </form>
                        </div>
                    </>
            }
        </div>
    )
}
const Shipping = memo(ShippingMemorize)
export default Shipping