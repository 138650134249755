import React, { useRef, memo, useState, useEffect } from "react";

import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from "swiper/react";
import 'swiper/css/pagination';

// import 'animate.css';
import './banCarousel.css';
import axios from 'axios';
import { apiBaseUrl } from '../../config/config';
import { removeTags, setToLocalStorage } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";



const Carousel2Memorize = () => {    
    const [imgPath, setImgPath] = useState([]);
    const [imgBasePath, setBasePath] = useState();
    const navigate = useNavigate()
    const getBanner = () =>{
        axios.get(`${apiBaseUrl}api/topbanner`)
        .then((res) => {
            setBasePath(res.data.img_path)
            setImgPath(res.data.message);        
        })
        .catch((err) => {
            console.error(err);
        });
    }

    useEffect(()=>{
        getBanner()
    },[])
    
    const slideref = useRef();
    // const swiperSlide = useSwiperSlide();

    const handlenext = () => {
        slideref.current.swiper.slideNext();
    };
    const handleprev = () => {
        slideref.current.swiper.slidePrev();
    };

    return (
        <div className="w-full static">

            <div className="">
                <Swiper 
                    loop={true}
                    modules={[Pagination, Navigation]}
                    spaceBetween={10}
                    centeredSlides={true}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    // navigation
                    // pagination={{ clickable: true, }}
                    // modules={[Autoplay, Pagination, Navigation]}
                    className=""
                    ref={slideref}
                >
                    {imgPath.map((item, index)=>{
                        return(
                            <SwiperSlide key={index} >
                                {/* <div className="h-[450px] scale-[0.999]" style={{ background: `url(${`${imgBasePath}/${item.banner_image}`})` }} > */}
                                <div className="relative" >
                                    { window.innerWidth <= 768 ? 
                                    <>
                                        {item.banner_link ?
                                            <>
                                            <Link to={item.banner_link}><img /* onClick={navigate('/' + item.banner_link)} */ className="w-full h-auto" src={`${imgBasePath}/${item.banner_image}`} alt="" /></Link>
                                            </>
                                            :
                                            ''
                                        }
                                    </>
                                    :
                                    <>
                                        <img className="w-full h-auto" src={`${imgBasePath}/${item.banner_image}`} alt="" />
                                        {item.banner_link ?
                                        <>
                                            <a className="btn bg-[#ec2028]/75 text-white hover:bg-[#ec2028]" href={item.banner_link} style={{position: 'absolute', zIndex: 1, bottom: '33px', left: '50%', transform: 'translateX(-50%)'}}> {item.banner_button} </a>
                                        </>
                                        :
                                        <></>
                                        }
                                    </>
                                    }
                                    {/* <div className="">
                                        <svg onClick={handleprev} className='w-10 h-10 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute right-5 top-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                        </svg>
                                        <svg onClick={handlenext} className='w-10 h-10 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute left-5 top-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                                        </svg>
                                    </div> */}
                                </div>
                            </SwiperSlide>
                        )
                    })}

                    <div className="sliderNav-wrapper">
                        <svg onClick={() => slideref.current.swiper.slidePrev()} className='slideNav-previous w-10 h-10 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute right-5 top-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                        </svg>
                        <svg onClick={() => slideref.current.swiper.slideNext()} className='slideNav-next w-10 h-10 rounded-full opacity-50 shadow-sm cursor-pointer p-1 text-[#8C6940] font-ext absolute left-5 top-[50%] bg-white' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" >
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                        </svg>
                    </div>
                    

                </Swiper>

            </div>

        </div>
    )
}
const Carousel2 = memo(Carousel2Memorize)
export default Carousel2