import React, { Fragment, useRef, useEffect, useState } from "react";
import Vector from "../../assets/Logo/hdrPattern.svg";
// import Vector from "../../assets/Logo/Vector.png";
import Logo from "../../assets/Logo/Logo.png";
import LogoEng from "../../assets/Logo/LogoEng.png";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import EventsDown from "../../assets/Logo/EventDurgaji.png";
import { useNavigate } from "react-router-dom";
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeAllFromLocalStorage,
} from "../../helpers/helpers";

import './navStyle.css'
import gsap from "gsap";
import {useGSAP} from "@gsap/react"
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
import { selectCartItemsLength } from "../../redux/cartSlice";
import { IoApps , IoSearch, IoCloseOutline } from "react-icons/io5";
import { LiaLongArrowAltRightSolid } from "react-icons/lia"
import { HiMiniArrowLongLeft } from "react-icons/hi2"
import { FaAngleRight, FaBuildingUser, FaCartShopping, FaClipboardUser, FaUser, FaUserTag } from "react-icons/fa6"
import { FaCogs, FaUserCog } from "react-icons/fa"
import { TbTruckDelivery } from "react-icons/tb"
import axios from "axios";
import { useCart } from "react-use-cart";
import { apiBaseUrl } from "../../config/config";


const Navbar = (setNavState) => {
  gsap.registerPlugin(useGSAP, ScrollTrigger);
  const gsapContainer = useRef();
  // const animSticky = useRef()winWidth <= 768;
  const animNav = useRef()
  const [name, setName] = useState()
  const [lastName, setLastName] = useState()
  const [winWidth, setWinWidth] = useState(window.innerWidth);
  const [winMobile, setMobile] = useState(window.innerWidth <= 768);
  const [isHamOpen, setIsHamOpen] = useState(false)
 
  const [isLogin, setIsLogin] = useState(false);
  const [cartItem, setCartItem] = useState();
  const [token, setToken] = useState(getFromLocalStorage("access_token"));
  const productLenghth = useSelector((state) => state.cart.Items);
  const [poster, setPoster] = useState([]);
  const navigate = useNavigate();


  const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const handleResizeScrn = () => {
          const isMobile = window.innerWidth <= 768; // Adjust breakpoint as needed
          setIsVisible(!isMobile); // Update state based on screen size
        };
        window.addEventListener('resize', handleResizeScrn);
    
        handleResizeScrn(); // Call the handler on initial render
    
        return () => window.removeEventListener('resize', handleResizeScrn);
    }, []);



  //***** Anim nav  *****//
  const navToggle = () => {
    setIsHamOpen(!isHamOpen)
    /* document.body.classList.add('nav-active')
    if(!isHamOpen){ document.body.classList.add('nav-active') }
    else{ document.body.classList.remove('nav-active') } */
    console.log('state is', isHamOpen)
  }
  useGSAP(() => {
    let elLogoBar = document.querySelector('.logoBar')
    let elSearch = document.querySelector('.searchBar')
    let elNavHolder = document.querySelector('.navHolder')
    let actn = document.querySelector('.eComAction')

    let mm = gsap.matchMedia()
    
    // console.log(elSearch.clientWidth);
    gsap.set(elSearch, {width: actn.clientWidth})  
    gsap.set(elLogoBar, {
      yPercent: 0,
      // zIndex: 1
    })
    // animSticky.current = gsap
    const animSticky = gsap.timeline({
      scrollTrigger: {
        // start: "top+=150",
        start: "top+=100",
        end: "+=1",
        toggleActions: 'play none none reverse',
        // scrub: true,
        // markers: true,
        // id: "scrub",
        ease: "power3.out",
      }
    })
    animSticky.to(elLogoBar, {
      // position: 'absolute',
      // top: 0,
      yPercent: -70,
      // position: 'fixed'
      // yPercent: 80
    })

      // console.log(document.querySelector(".SiteNav"))
      const elNav = document.querySelector(".SiteNav");
      const elBody = document.body;

        gsap.set(elNav, {x: '-100%'})
        animNav.current = gsap.timeline({paused: true})
        .to(elNav, {
          x: 0,
          boxShadow: '1px 0 7px 0 #0000002b',
        })
        .to(elBody, {
          // className: '-=active',
          // className: 'mobile-active nav-active',
          className: 'mobile-active nav-active',
          maxHeight: window.innerHeight,
          overflow: 'hidden'
        })

  }, { scope: gsapContainer });

  
  if(isVisible == true){
    const elNav = document.querySelector(".SiteNav");
    
    document.body.classList.remove('mobile-active')
    if(document.body.classList.contains('nav-active')){
      document.body.classList.remove('nav-active')
    }
  }
  if(isVisible == false){
    document.body.classList.add('mobile-active')
  }
  useEffect(() => {
    // console.log('Hello SD', isVisible)
    if(isHamOpen) {
      animNav.current.play()
    } else {
      animNav.current.reverse()
    }
  },[isHamOpen])
  

  function handleWindowSizeChange() {
    setWinWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    // window.addEventListener('resize', activeNav);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
        // window.removeEventListener('resize', activeNav);
    }
  }, []);

  // const isMobile = winWidth <= 768;
  
  // if (winMobile) {
  /* if (isVisible) {
    // console.log(document.body.classList.contains('mobile-active'))
    if(!document.body.classList.contains('mobile-active')){
      document.body.classList.add('mobile-active');
    };
  } else {
    // setNavState(true);
    // console.log(document.body.classList.contains('mobile-active'))
    if(document.body.classList.contains('mobile-active')){
      document.body.classList.remove('mobile-active');
    };
  } */


  useEffect(() => {
    // animFnctn()
    setIsLogin(getFromLocalStorage("isLogin"));
  },[]);
  // const logOut = () => {
  //   removeAllFromLocalStorage()
  //   setToLocalStorage("islogin", false)
  //   navigate("/login")
  // }
  const { totalItems } = useCart();  
  const goToProductPage = () => {
    localStorage.removeItem("CId");

    navigate("/productlist");
  };
  const originalText = "Search for saree";

  const getCategoryList = () => {
    axios
      .get(`${apiBaseUrl}api/category-list`)
      .then((res) => {
        
        setPoster(res.data);
      })
      .catch((err) =>
        console.log("This is error at the time of category api call", err)
      );
  };
  useEffect(() => {
    getCategoryList();
  }, []);
  const goToCategory = (id) => {
    localStorage.setItem("CId", id);
    navigate(`/productlist/${id}`);
  };

  useEffect(() => {
    setName(getFromLocalStorage("username"))
    setLastName(getFromLocalStorage("last_name"))
  }, [])
  const logOut = () => {
    removeAllFromLocalStorage()
    setToLocalStorage("islogin", false)
    navigate("/login")
  }



  return (
    <>
      {window.innerWidth <= 768 ? 
          <>
          <div className="SiteNav" ref={gsapContainer}>
            <div className="navWrapper">
              {/* <IoCloseOutline className="text-3xl cursor-pointer mr-[.9rem] text-red-100 textTheme" onClick={navToggle} /> */}
              <div className="nav-top bg-white">
                <div className="text-end"><button className="btn m-1 !p-1 !px-2 !h-auto min-h-[auto] !rounded-lg bg-red-100 text-red-600" onClick={navToggle}><HiMiniArrowLongLeft className="text-[1.7rem]" /></button></div>
                {isLogin?
                <>
                  <div className="flex justify-start mx-auto items-center idUser text-sm p-3 bg-gray-100">
                    <FaClipboardUser className="text-[2.2rem]"/>
                    {/* <FaUser className="w-6 h-6" /> */}
                    <div className="ml-2">
                      <div className="flex">
                        <p className="m-0 text-[1rem]">{name} {lastName}</p>
                        <span className="ml-2 -mt-1 text-amber-500" onClick={()=>{navigate('/profile')}}><FaCogs /></span>
                      </div>
                      <small className="tracking-[.2em] uppercase underline text-red-600" onClick={logOut}>Logout</small>
                    </div>
                  </div>
                </>
                :
                <>
                  <Link to={`${isLogin}` ? "/profile" : "/login"} className="flex justify-start mx-auto items-center  text-sm p-3 bg-gray-100">
                    <FaClipboardUser className="text-[2.2rem]"/>
                    {/* <FaUser className="w-6 h-6" /> */}
                    <div className="ml-2">
                      <p className="m-0 text-[1rem]">Hello Guest</p>
                      <small className="tracking-[.2em] uppercase underline text-red-600">Login</small>
                    </div>
                  </Link>
                </>
                }
              </div>
              {isLogin ? 
              <div className="userAccount px-3 py-2">
                <div className="toggAccor">
                  <div className="flex justify-between items-center">
                    <span className="text-[#dc2626] m-0">My Account</span>
                    <FaAngleRight className="text-red-600" />
                  </div>
                  <small className="text-[.7rem] leading-tight">Address, Favourites & Settings</small>
                </div>
                <ul className="u-pan font-Century text-black text-[12px] font-semibold">
                  <li className="u-pan-items">
                    <Link className="flex items-center" to={'/profile'}><FaUserCog className="text-[1.35em] text-[#0000005e] mr-2" /> Profile Settings</Link>
                  </li>
                  <li className="u-pan-items">
                    <Link className="flex items-center" to={'/profile/shippingadress'}><FaBuildingUser className="text-[1.35em] text-[#0000005e] mr-2" /> Manage Address</Link>
                  </li>
                  <li className="u-pan-items">
                    <Link className="flex items-center" to={'/profile/orders'}><TbTruckDelivery className="text-[1.35em] text-[#0000005e] mr-2" /> Orders</Link>
                  </li>
                  {/* <li className="u-pan-items">
                    <Link className="flex items-center" to={'/profile/shippingadress'}><FaUserTag className="text-[1.35em] text-[#0000005e] mr-2" /> Favourites</Link>
                  </li> */}
                </ul>
              </div>
              : ''}
              
              <ul className="mobNav-list flex flex-col justify-start mt-3 mx-auto text-sm  cursor-pointer navHolder">
                <li className="mobNav-item px-3"><Link className="py-2 block text-[#dc2626] hover:text-indigo-600" to="/">Home</Link></li>{" "}
              
                <li className="mobNav-item px-3">
                  <Menu as="div" className="relative w-full text-left">
                    <div className="flex items-center justify-between py-2">
                      <Menu.Button className="block text-[#dc2626]">Saree</Menu.Button>
                      <button className="text-black text-[.8rem] flex text-red-600" onClick={goToProductPage}>
                        View All{" "}
                        <LiaLongArrowAltRightSolid className="text-[1.2rem] ml-1" />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 ml-1 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                        </svg> */}
                      </button>
                    </div>
                    <div className="space-x-4 border-t-[1px] border-dashed border-[#00000033] divide-x divide-yellow-500 p-2 pt-5 mb-5 border-top">
                      <div>
                        {/* <div className="flex justify-between items-center w-full mb-2">
                          <h1 className="text-white font-semibold text-md  ">Select category</h1>
                          <button className="text-black text-sm flex " onClick={goToProductPage}>
                            View all{" "}
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 ml-1 h-5">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                          </button>
                        </div> */}
                        <div className="flex flex-wrap ">
                          <ul className="list-none text-xs basis-auto grow space-y-2">
                            {poster?.slice(0, 6).map((item, index) => {
                              return (
                                <li key={index} className="font-Century text-black text-[12px] font-semibold text-md  mt-1 hover:text-red-600 " onClick={() => goToCategory(item?.id)}>
                                  {item?.category_name}
                                </li>
                              );
                            })}
                          </ul>
                          <ul className="list-none text-xs basis-[42%] space-y-2">
                            {poster?.slice(6, 12).map((item, index) => {
                              return (
                                <li  key={index} className="font-Century text-black text-[12px] font-semibold text-md  mt-1  " onClick={() => goToCategory(item?.id)}>
                                  {item?.category_name}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>

                  </Menu>
                </li>
                <li className="mobNav-item px-3"><Link className="py-2 block text-[#dc2626] hover:text-indigo-600" to="/events">Events</Link></li>
                <li className="mobNav-item px-3"><Link className="py-2 block text-[#dc2626] hover:text-indigo-600" to="/aboutus">About Us</Link></li>
                <li className="mobNav-item px-3"><Link className="py-2 block text-[#dc2626] hover:text-indigo-600" to="/contact">Contact</Link></li>
              </ul>
            </div>
          </div>
          </>
          :
          <></>
      }
      <div className="sticky z-10 top-0 logoBar " ref={gsapContainer}>
        <div className="shadow-2xl pb-0 border-b bg-white">
          <div className="flex justify-center">
            <img className="h-36 w-full static " src={Vector} alt="vector" />
            <img className="h-24 w-36 absolute top-5 " src={Logo} alt="logo" />
          </div>
          <div className="flex items-center px-5 ">
            {window.innerWidth <= 768 ? 
            <IoApps className="text-3xl cursor-pointer mr-[.9rem] text-red-600" onClick={navToggle} /> 
            :''}
            <div className="searchBar flex items-center">
              <img className="h-[3.5em] mr-2" src={LogoEng} alt="logo" />
            </div>
            {/* <ul className="flex justify-center ml-60 space-x-7 items-center  text-sm  cursor-pointer"> */}
            {window.innerWidth <= 768 ? 
            <></>
            :
            <>
              <ul className="flex justify-center mx-auto space-x-7 items-center  text-md font-semibold cursor-pointer navHolder">
              
                <li><Link className="text-[#dc2626] hover:text-slate-800" to="/">Home</Link></li>{" "}
              
                <li>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="text-[#dc2626]">Saree</Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute p-2 left-0 mt-4 w-auto h-auto py-5 origin-top-right divide-y bg-white divide-gray-100 rounded-md  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="flex space-x-4 divide-x divide-yellow-500 ">
                          <div>
                            <div className="flex justify-between mb-2">
                              <h1 className="font-serif text-black font-bold text-lg  ">
                                Select category
                              </h1>
                              <button
                                onClick={goToProductPage}
                                className="text-red-600 text-sm flex "
                              >
                                View all{" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth="2"
                                  stroke="currentColor"
                                  className="w-5 ml-1 h-5"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="flex justify-around">
                              <ul className="text-xs font-poppins text-black list-disc w-40 pl-10 space-y-2">
                                {poster?.slice(0, 6).map((item, index) => {
                                  return (
                                    <li key={index} className="font-Century text-[12px] font-semibold text-md  mt-1 hover:text-red-600 " onClick={() => goToCategory(item?.id)}>
                                      {item?.category_name}
                                    </li>
                                  );
                                })}
                              </ul>
                              <ul className="text-xs font-poppins text-black list-disc w-40 pl-10 space-y-2">
                                {poster?.slice(6, 12).map((item, index) => {
                                  return (
                                    <li  key={index} className="font-Century text-[12px] font-semibold text-md  mt-1  " onClick={() => goToCategory(item?.id)}>
                                      {item?.category_name}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </li>
                <li><Link className="text-[#dc2626] hover:text-slate-800" to="/events">Events</Link></li>
                <li><Link className="text-[#dc2626] hover:text-slate-800" to="/aboutus">About Us</Link></li>
                <li><Link className="text-[#dc2626] hover:text-slate-800" to="/contact">Contact</Link></li>
              </ul>
            </>
            }
            <div className={window.innerWidth <= 768 ? "ml-auto font-serif flex justify-end items-center eComAction" : 'font-serif flex justify-end items-center eComAction'}>
              {/* <div className=" flex space-x-8"> */}
              <div className=" flex space-x-[1.2rem] md:space-x-8 ">
                
                <div className=" flex border border-red-600 bg-white justify-center items-center rounded py-1 shadow-lg">
                  <IoSearch className="text-2xl  mx-2 text-red-600" />
                  <input
                    type="text"
                    className="focus:outline-none font-thin placeholder:font-thin bg-white"
                    placeholder={originalText}
                    hidden
                  />
                </div>
                {window.innerWidth <= 768 ?
                ''
                :
                <Link to={`${isLogin}` ? "/profile" : "/login"}>
                  <FaUser className="w-6 h-6 text-red-600" />
                </Link>
                }
                {/* <Link to="/trackingorder">
                  <TbTruckDelivery className="w-7 h-7 text-red-600" />
                </Link> */}
                <Link to={`${isLogin}` ? "/cart" : "/login"}>
                  <div className="relative">
                    <FaCartShopping className="text-red-600 w-6 h-6" />
                    <p className="bg-yellow-500 w-5 h-5 text-xs rounded-full flex justify-center items-center  absolute -top-2 -right-2">
                      {totalItems}
                    </p>
                  </div>
                </Link>
              </div>
              {/* isLogin ? null : (
                <Link to="/login">
                  {" "}
                  <button className=" text-red-500 border-2 w-24 border-red-600 text-[11px] p-1.5 rounded-md hover:bg-red-100">
                    Login/Signup
                  </button>
                </Link>
              ) */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
