
import './App.css';
import 'react-notifications/lib/notifications.css';
import { useState } from 'react';
import { HashRouter , Link, Routes, BrowserRouter as Router, Route, Navigate, Switch } from "react-router-dom";
import {
  setToLocalStorage,
  getFromLocalStorage,
  removeAllFromLocalStorage,
} from "./helpers/helpers";

import Home from './pages/home/Home';
import NoPage from './pages/nopage/NoPage';
import Contact from './pages/contact/Contact';
import Saree from './pages/saree/saree'
import Events from './pages/events/Events';
import AboutUs from './pages/aboutUs/AboutUs';
import Login from './pages/registration/Login';
import Signup from './pages/registration/Signup';
import Cart from './pages/cart/Cart';
import Support from './pages/support/Support';
// import MyState from './context/data/myState';
import ProductList from './pages/productList/ProductList';
import ProductDetailsPage from './pages/productDetailsPage/ProductDetailsPage';
import ScrollToTop from './components/scrollToTop/ScrollToTop'
import TrackingOrder from './pages/trackingOrder/TrackingOrder';
import Profile from './pages/profile/Profile';
import Checkouts from './pages/checkouts/Checkouts';
import ShippingAddress from './pages/shipingAddress/ShippingAddress'; 
import { NotificationContainer } from 'react-notifications';
import EditProfile from './pages/profile/EditProfile';
import ForgotPassword from './pages/registration/ForgotPassword';
import Calender from './pages/calender/Calender';
import Success from './pages/payment/Success';
import Failed from './pages/payment/Failed';
import PaymentResponseHandler from './pages/payment/PaymentResponseHandler';
import EditShipingAddress from './pages/profile/EditShipingAddress';
import ViewOrder from './pages/profile/ViewOrder';
import Aborted from './pages/payment/Aborted';
import PastOrderHistory from './pages/profile/PastOrderHistory';
import HelpAndSupport from './pages/helpAndSupport/HelpAndSupport';
import ReturnOrExchangepolicy from './pages/returnOrExchangepolicy/ReturnOrExchangepolicy';
import TermsAndConditions from './pages/termsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import Invoice from './components/invoice/Invoice';
import { FaWhatsapp } from "react-icons/fa6";
import EmailConfirmation from './pages/registration/EmailConfirmation';
import ReSet from './pages/registration/ReSet';
import EventsView from './pages/events/EventsView';
import { CartProvider } from "react-use-cart";



function App() {
  
  const [isLogin, setIsLogin] = useState(false);
  // const [token, setToken] = useState(getFromLocalStorage("access_token"));
  useState(()=>{
    setIsLogin(getFromLocalStorage("isLogin"));
  },[])
  const isLoggedIn = () => {
    // Your authentication logic here
    // For example, check if the user is logged in from your authentication state
    if(isLogin){
      return true;
    }
    return false; // Placeholder logic, replace with your actual authentication check
  };
  
  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? <Component {...props} /> : <Navigate to="/login" />
      }
    />
  );
  const today = new Date();
  const current_month = today.getMonth() + 1;
  const current_year = today.getFullYear();
  const current_date = today.getDate();
  return (
    <div className='bg-white   relative'>
      <CartProvider>
      <Router>
        <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/saree' element={<Saree />} />
              <Route path='/events' element={<Events />} />
              <Route path='/event/:id' element={<EventsView/>}/>
              <Route path='/aboutus' element={<AboutUs />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/login' element={<Login />} />
              <Route path='/signup' element={<Signup />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/productlist' element={<ProductList />} />
              <Route path='/productlist/:id' element={<ProductList />} />
              {/* <Route path='/productDetails' element={<ProductDetailsPage />} /> */}
              <Route path='/saree/:product' element={<ProductDetailsPage />} />
              <Route path='/trackorder/:id' element={<TrackingOrder/>}/>
              <Route path='/support' element={<Support />} />


              <Route path='/profile' element={<Profile/>}/>
              <Route path='/profile/:profileRoutes' element={<Profile/>}/>


              {/* <Route path='/edit-profile' element={<EditProfile/>}/> */}
              {/* <Route path='/view-order' element={<ViewOrder/>}/> */}
              {/* <Route path='/past-order' element={<PastOrderHistory/>}/> */}

              
              {/* <Route path='/editshippingadress' element={<EditShipingAddress/>}/> */}
              <Route path='/forgot-password' element={<ForgotPassword/>}/>
              <Route path='/email-conformation' element={<EmailConfirmation/>}/>
              <Route path='/reset' element={<ReSet/>}/>
              <Route path='/calender' element={<Calender/>}/>
              <Route path='/paymentsuccess' element={<Success/>}/>
              <Route path='/paymentfailed' element={<Failed/>}/>
              <Route path='/aborted' element={<Aborted/>}/>
              <Route path='paymentresponse' element={<PaymentResponseHandler/>}/>
              <Route path='/help_and_Support' element={<HelpAndSupport/>}/>
              <Route path='/return_or_exchange_policy' element={<ReturnOrExchangepolicy/>}/>
              <Route path='/terms_and_conditions' element={<TermsAndConditions/>}/>
              <Route path="privacy_policy" element={<PrivacyPolicy/>}/>
              <Route path='invoice' element={<Invoice/>}/>
              <Route path='/*' element={<NoPage />} />
        </Routes>
      </Router>
      </CartProvider>      
      {/* </MyState> */}
   
    </div>
  );
}

export default App;
