import React,{memo, useEffect, useState} from 'react'
import { getFromLocalStorage, setToLocalStorage } from "../../helpers/helpers";
import { useNavigate } from 'react-router-dom'

function DiscoverMoreBestsellersMemorize() {
    const today = new Date();
    const [currentDate, setCurrentDate] = useState(today);
    const [imgPath, setImgPath] = useState('');
    const [productList, setProductList] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        const storedData = getFromLocalStorage("allProductData");
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          setImgPath(parsedData?.img_path);
          setProductList(parsedData?.message);
          console.log("Parsed data:", parsedData);
        }
      }, []);

      const exclusiveCollectionData = productList
      ?.filter(item => item.is_2023_trendsetters === 1);
      console.log("this is 2023",exclusiveCollectionData)

      const setProductdataInLocalstorage = (id,product_name,color_name) => {
        const prodTitle = product_name
        const prodColor = color_name
        const slug = ((prodTitle)? prodTitle : "").replace(/\W+/g, '-') +((prodColor)? "-"+prodColor : "").replace(/\W+/g, '-')+'-'+id
        const titleToLink = "saree/"+slug.toLowerCase();
        localStorage.setItem("ProductId", id)
        navigate(titleToLink);
      }

    return (
        <div className="flex justify-center items-center">
            <div className="2xl:mx-auto 2xl:container py-12 px-4 sm:px-6 xl:px-20 2xl:px-0 w-full">
                <div className="flex flex-col jusitfy-center items-center space-y-10">
                    <div className="flex flex-col justify-center items-center space-y-2">
                        <h1 className='font-Lucida text-[2em] md:text-[3em] font-medium text-red-600 red-title-shadow'>{currentDate.getFullYear()} Trendsetters</h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-x-4  w-full">
                    { 
                        exclusiveCollectionData.slice(0,1).map((item, index)=>{
                            const newImg = item.product_image ? item.product_image.split(",") : [];
                            return(
                                <div className="relative group flex justify-center items-center h-full w-full" key={index} onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>
                                <img className="object-center object-cover h-full w-full" src={`${imgPath}/${newImg[0]}`} alt="girl-image" />
                                <button className="focus:outline-none focus:ring-2  focus:ring-offset-2 focus:ring-gray-400 bottom-4  absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white" onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>Buy Now </button>
                                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" />
                            </div>
                            )
                        })
                    }
                        <div className="flex flex-col space-y-4 md:space-y-8 mt-4 md:mt-0">
                        {
                            exclusiveCollectionData.slice(1,2).map((item, index)=>{
                                const newImg = item.product_image ? item.product_image.split(",") : [];
                                return(
                                <div className="relative group flex justify-center items-center h-full w-full" key={index} onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>
                                    <img className="object-center object-cover h-full w-full" src={`${imgPath}/${newImg[0]}`} alt="saree" />
                                    <button className="focus:outline-none focus:ring-2 cursor-pointer   focus:ring-offset-2 focus:ring-gray-400 bottom-4  absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white" onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>Buy Now</button>
                                    <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" />
                                </div>
                                )
                            })
                        }
                        {
                            exclusiveCollectionData.slice(2,3).map((item, index)=>{
                                const newImg = item.product_image ? item.product_image.split(",") : [];
                                return(
                                <div className="relative group flex justify-center items-center h-full w-full" key={index} onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>
                                    <img className="object-center object-cover h-full w-full" src={`${imgPath}/${newImg[0]}`} alt="image" />
                                    <button className="focus:outline-none focus:ring-2 cursor-pointer  focus:ring-offset-2 focus:ring-gray-400 bottom-4  absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white" onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>Buy Now</button>
                                    <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" />
                                </div>
                                )
                            })
                        }
                        </div>
                    {
                        exclusiveCollectionData.slice(3,4).map((item, index)=>{
                            const newImg = item.product_image ? item.product_image.split(",") : [];
                            return(
                            <div className="relative group justify-center items-center h-full w-full hidden lg:flex" key={index} onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>
                                <img className="object-center object-cover h-full w-full" src={`${imgPath}/${newImg[0]}`} alt="image" />
                                <button className="focus:outline-none focus:ring-2 cursor-pointer  focus:ring-offset-2 focus:ring-gray-400 bottom-4  absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white" onClick={() => setProductdataInLocalstorage(item.id,item.product_name, item.color_name)}>Buy Now</button>
                                <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" />
                            </div>
                            )
                        })
                    }
                        {/* <div className="relative group flex justify-center items-center h-full w-full mt-4 md:hidden md:mt-8 lg:hidden">
                            <img className="object-center object-cover h-full w-full hidden md:block" src="https://i.ibb.co/6FjW19n/olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2.png" alt="girl-image" />
                            <img className="object-center object-cover h-full w-full md:hidden" src="https://i.ibb.co/sQgHwHn/olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-1.png" alt="olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2" />
                            <button className="focus:outline-none focus:ring-2 cursor-pointer  focus:ring-offset-2 focus:ring-gray-400 bottom-4  absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-white" >Buy Now</button>
                            <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" />
                        </div> */}
                    </div>
                    {/* <div className="relative group hidden md:flex justify-center items-center h-full w-full mt-4 md:mt-8 lg:hidden">
                        <img className="object-center object-cover h-full w-full hidden md:block" src="https://i.ibb.co/6FjW19n/olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2.png" alt="girl-image" />
                        <img className="object-center object-cover h-full w-full sm:hidden" src="https://i.ibb.co/sQgHwHn/olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-1.png" alt="olive-tatiane-Im-Ez-F9-B91-Mk-unsplash-2" />
                        <button className="focus:outline-none focus:ring-2 cursor-pointer focus:ring-offset-2 focus:ring-red-400 bottom-4  absolute text-base font-medium leading-none text-gray-800 py-3 w-36 bg-red-600">Buy Now</button>
                        <div className="absolute opacity-0 group-hover:opacity-100 transition duration-500 bottom-3 py-6 z-0 px-20 w-36 bg-white bg-opacity-50" />
                    </div> */}
                </div>
            </div>
        </div>
    )
}
const DiscoverMoreBestsellers = memo(DiscoverMoreBestsellersMemorize)
export default DiscoverMoreBestsellers
