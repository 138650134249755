import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { IoMdPaper } from "react-icons/io";
import { MdDone } from "react-icons/md";
import axios from 'axios';
import { apiBaseUrl } from '../../config/config';
import { getFromLocalStorage } from "../../helpers/helpers";
import { useCart  } from "react-use-cart";


const Success = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const amount = searchParams.get("amount");
    const orderId = searchParams.get("orderid");
    const [token, setToken] = useState(getFromLocalStorage("access_token"))
    const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
    };
    const { emptyCart} = useCart();  
    const clearCart = () =>{
        emptyCart();
        axios.delete(`${apiBaseUrl}api/removecart/1`,{headers})
        .then(()=>{
            console.log("cart clear");
        })
        .catch((err)=>console.log("At the time of payment cart clear"))
    }
    useEffect(()=>{
        clearCart()
    },[])
    return (
        <Layout>
            <div className='h-screen flex justify-center items-center  bg-gray-200 '>
                <div className='bg-white w-[70%] shadow-2xl rounded-md '>
                    <div className='bg-white h-60 w-60 rounded-full p-5  mx-auto -mt-20 relative'>
                        <div className='w-full h-full rounded-full bg-[#43B28A] p-5'>
                            <div className='w-full h-full rounded-full bg-[#0B6A4A] flex justify-center items-center'>
                                <IoMdPaper className='text-white text-[80px]' />
                            </div>
                            <div className=' bg-white h-20 w-20 rounded-full absolute bottom-5 right-2 p-1'>
                                <div className='w-full h-full rounded-full bg-[#106749] flex justify-center items-center'>
                                    <MdDone className='text-white text-[50px]' />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='w-full flex justify-center mt-8'>
                        <p className='font-bold text-black text-4xl mx-auto tracking-wider'>₹ {amount}</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif '>Order ID : {orderId}</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className='font-bold text-black text-3xl font-serif mt-7'>Payment Successful!</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif mt-2'>The payment has been made successfully</p>
                    </div>
                    <div className='w-full flex justify-center'>
                        <p className=' text-black text-xl font-serif mt-2 '>Thanks you for being there with us</p>
                    </div>
                    <div className='w-full flex justify-center mt-5 mb-5'>
                        <Link to={'/productlist'}><button className='bg-[#0B6A4A] w-40 rounded h-10 text-white  text-lg'>Done</button></Link>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Success
