import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Layout from '../../components/layout/Layout';
import Logo from '../../assets/Logo/Logo.png';
import { apiBaseUrl } from '../../config/config';
import { NotificationManager } from 'react-notifications';
import {setToLocalStorage, textTruncate} from "../../helpers/helpers";
import Loding from '../../components/Loding/Loding';
const Login = () => {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loding, setLoading]=useState(false)
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const [post, setPost] = useState()
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [hasLowerCase, setHasLowerCase] = useState(false)
  const [hasUpperCase, setHasUpperCase] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [hasSpecialChar, setHasSpecialChar] = useState(false)
  const [hasMinimumLength, setHasMinimumLength] = useState(false)
  const [addressFormError, setAddressformError] = useState()

  useEffect(() => {
    console.log("addressformerror", addressFormError)       
}, [addressFormError])
  useEffect(() => {
    if(email){
      setIsValidEmail(email?.includes("@")&&email?.includes("."));
    }
  }, [email]);

  useEffect(() => {
    if(password){
      setHasLowerCase(/[a-z]/.test(password));
      setHasUpperCase(/[A-Z]/.test(password));
      setHasNumber(/[0-9]/.test(password));
      setHasSpecialChar(/[!@#$%^&*()_+\-=\[\]{};':\\|,.<>/?]/.test(password));
      setHasMinimumLength(password?.length >= 8);
    }
  }, [password]);


  const handleLogin = async () => {
    try {
      setLoading(true)

      const response = await axios.post(`${apiBaseUrl}api/login`, { email: email, password: password });
      console.log('Login success:', response.data);
      setPost(response.data)
      setToLocalStorage("username", response.data.user.name)
      setToLocalStorage("last_name", response.data.user.last_name)
      setToLocalStorage("useremail", response.data.user.email)
      setToLocalStorage("phone", response.data.user.phone)
      setToLocalStorage("gender", response.data.user.gender)
      setToLocalStorage("access_token", response.data.access_token)
      setToLocalStorage("isLogin",true)
      setToLocalStorage("userId",response?.data?.user?.id)
      NotificationManager.success(<p className='text-xs'>Login success  </p>);
      setLoading(false)
      navigate('/profile')
    } catch (err) {
      var errors= {}
      console.log("This is error", err, err.response.data.message)
      errors.email = err.response.data.message?.email?.join(", ")
      errors.password = err.response.data.message?.password?.join(", ")
      setAddressformError(errors)
      setLoading(false)

    }
  };

  return (
    <Layout>

      {
        loding ? 
        <div className='py-2 text-center min-h-[4rem] flex justify-center items-center'>
          <Loding/>
        </div>
        :
        <section className="h-full bg-neutral-200 dark:bg-neutral-700">
        <div className="container h-full mx-auto p-10">
          <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 dark:text-neutral-200">
            <div className="w-full">
              <div className="block rounded-lg bg-white shadow-lg dark:bg-neutral-800">
                <div className="g-0 lg:flex lg:flex-wrap">
                  <div className="px-4 md:px-0 lg:w-6/12">
                    <div className="md:mx-6 md:p-12">
                      <div className="text-center mb-10">
                        <img className="mx-auto w-48" src={Logo} alt="logo" />
                      </div>

                      <form className='flex flex-col'>
                      {/* <div className='text-center'>
                        <p className='text-xs text-red-500'>{error?error:''}</p>
                      </div> */}
                      {/* {error?.message ? 'User or Password is invellid' : ''} */}
                      {error?<><span className='w-fit bg-red-600 text-white px-3 py-1 rounded-md mx-auto my-3 bg-gradient-to-r from-[#ee7724] via-[#d8363a] via-[#dd3675] to-[#b44593] animate-animBGclr bg-[length:200%_100%] '>{error}</span></>:''}

                        <p className="mb-4">Please login to your account</p>

                        <div className="mb-3">
                            <label htmlFor='email' className="sr-only">
                              User Name
                            </label>
                            <input
                              type='text'
                              id='email'
                              name='email'
                              value={email}
                              onChange={(e)=>setEmail(e.target.value)}
                              className="w-full h-10 px-5 border-2 border-[#C0408A] focus:outline-none rounded-md"
                              placeholder={`Enter User name `}
                            />
                            {isValidEmail?<></>:<small className='text-red-600'>{addressFormError?.email} </small>}

                          </div>

                          <div className="mb-3">
                            <label htmlFor='password' className="sr-only">
                              Password
                            </label>
                            <input
                              type='password'
                              id='password'
                              name='password'
                              value={password}
                              onChange={(e)=>setPassword(e.target.value)}
                              className="w-full h-10 px-5 border-2 border-[#C0408A] focus:outline-none rounded-md"
                              placeholder={`Enter Password`}
                            />
                            {(hasLowerCase&&hasUpperCase&&hasNumber&&hasSpecialChar&&hasMinimumLength)?<></>
                            :
                            <small className='text-red-600'>{addressFormError?.password} </small>}
                          </div>
                        <div className="mb-12 text-center">
                          <button
                            className="w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                            type="button"
                            onClick={handleLogin}
                            style={{
                              background: "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                            }}
                          >
                            Log in
                          </button>
                        </div>
                        <Link to="/forgot-password" className="hover:text-blue-500">Forgot password?</Link>
                        <div className="flex items-center justify-between pb-6">
                          <p className="mb-0 mr-2">Don't have an account?</p>
                          <Link to="/signup">
                            <button
                              type="button"
                              className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700 dark:hover:bg-neutral-100 dark:hover:bg-opacity-10"
                            >
                              Register
                            </button>
                          </Link>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="flex items-center rounded-b-lg lg:w-6/12 lg:rounded-r-lg lg:rounded-bl-none"
                    style={{
                      background: "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                    }}
                  >
                    {/* <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                      <h4 className="mb-6 text-xl font-semibold">We are more than just a company</h4>
                      <p className="text-sm">
                       {textTruncate(" Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",500)}
                      </p>
                    </div> */}
                    <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                      <h4 className="mb-6 text-xl font-semibold">Login / Register</h4>
                      <p className="text-sm">
                      Use coupon LAUNCH5 to get 5% off on your first purchase from our website. Valid only on 1st purchase of user.
                      </p>
                      <p className="text-sm">
                      Happy Shopping !
                      </p>
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
    </Layout>
  );
};

export default Login;